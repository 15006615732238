exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-as-advantages-jsx": () => import("./../../../src/pages/as/Advantages.jsx" /* webpackChunkName: "component---src-pages-as-advantages-jsx" */),
  "component---src-pages-as-cta-jsx": () => import("./../../../src/pages/as/CTA.jsx" /* webpackChunkName: "component---src-pages-as-cta-jsx" */),
  "component---src-pages-as-faq-jsx": () => import("./../../../src/pages/as/FAQ.jsx" /* webpackChunkName: "component---src-pages-as-faq-jsx" */),
  "component---src-pages-as-features-jsx": () => import("./../../../src/pages/as/Features.jsx" /* webpackChunkName: "component---src-pages-as-features-jsx" */),
  "component---src-pages-as-footer-2-jsx": () => import("./../../../src/pages/as/Footer2.jsx" /* webpackChunkName: "component---src-pages-as-footer-2-jsx" */),
  "component---src-pages-as-footer-jsx": () => import("./../../../src/pages/as/Footer.jsx" /* webpackChunkName: "component---src-pages-as-footer-jsx" */),
  "component---src-pages-as-index-jsx": () => import("./../../../src/pages/as/index.jsx" /* webpackChunkName: "component---src-pages-as-index-jsx" */),
  "component---src-pages-as-logo-cloud-jsx": () => import("./../../../src/pages/as/LogoCloud.jsx" /* webpackChunkName: "component---src-pages-as-logo-cloud-jsx" */),
  "component---src-pages-as-main-screen-jsx": () => import("./../../../src/pages/as/MainScreen.jsx" /* webpackChunkName: "component---src-pages-as-main-screen-jsx" */),
  "component---src-pages-as-navbar-jsx": () => import("./../../../src/pages/as/Navbar.jsx" /* webpackChunkName: "component---src-pages-as-navbar-jsx" */),
  "component---src-pages-as-numbers-jsx": () => import("./../../../src/pages/as/Numbers.jsx" /* webpackChunkName: "component---src-pages-as-numbers-jsx" */),
  "component---src-pages-as-pricing-jsx": () => import("./../../../src/pages/as/Pricing.jsx" /* webpackChunkName: "component---src-pages-as-pricing-jsx" */),
  "component---src-pages-as-testimonials-jsx": () => import("./../../../src/pages/as/Testimonials.jsx" /* webpackChunkName: "component---src-pages-as-testimonials-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

